import React from "react"
import PropTypes from "prop-types"
import { Button } from "grommet"
import { navigate } from "gatsby"

const RoutedButton = ({ to, onClick = () => {}, ...rest }) => (
  <Button
    href={to}
    onClick={ev => {
      onClick();
      navigate(to);
    }}
    {...rest}
  />
)

RoutedButton.propTypes = {
  to: PropTypes.string,
}
export default RoutedButton
