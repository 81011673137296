import React, { useContext, useMemo } from "react"
import { Anchor, Box, Header as GrommetHeader, Heading, ResponsiveContext } from "grommet"
import Link from "./link"
import { Logo } from "./logo"
import RoutedButton from "./routedButton"
import { Dashboard } from "grommet-icons"
import { outboundClick } from "../helpers/analytics"

const Header = () => {
  const size = useContext(ResponsiveContext)
  const isBigScreen = useMemo(() =>
    size !== "xsmall" && size !== "small",
    [size])
  return (
    <GrommetHeader pad={{ top: "medium", horizontal: isBigScreen ? "large" : 'small' }}>
      <Link
        icon={<Logo />}
        color="black"
        style={{ textDecoration: "none" }}
        label={
          isBigScreen && <Heading level={1} size="small">mitabits</Heading>
        }
        to="/"
      />
      <Box direction="row" gap="small" align="center">
        <Anchor
          onClick={() => outboundClick(`${process.env.GATSBY_DASHBOARD_URL}/demo`, "demo")}
          target="_blank"
          size="large"
          href={`${process.env.GATSBY_DASHBOARD_URL}/demo`}>DEMO</Anchor>
        {isBigScreen && <RoutedButton to="/roadmap" label="Развитие" plain />}
        <Box as="span" background="border" width="1px" height="24px" round="xsmall" />
        <RoutedButton to="/contact-us" label="Контакти" plain />
        <RoutedButton
          onClick={() => outboundClick(process.env.GATSBY_DASHBOARD_URL, "dashboard")}
          to={process.env.GATSBY_DASHBOARD_URL}
          label={isBigScreen && "Dashboard"}
          icon={<Dashboard />}
          primary
          size="large"
          color="brand"
        />
      </Box>
    </GrommetHeader>
  )
}

export default Header
