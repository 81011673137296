import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import { Grommet, Box } from "grommet"
import { customTheme } from "../theme"
import { Footer } from "./footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
      query SiteTitleQuery {
          site {
              siteMetadata {
                  title
              }
          }
      }
  `)

  // TODO: make Main not do height 100% and use it...
  // TODO: any other way to make Grommet do display:flex?
  //       these two tweaks make main take up the middle and
  //       the footer stay at the bottom
  return (
    <Grommet
      theme={customTheme}
      full
      // style={{
      //   display: "flex",
      //   flexDirection: "column",
      // }}
    >
      <Header siteTitle={data.site.siteMetadata.title} />
      <Box as="main" overflow="auto">
        {children}
      </Box>
      <Box align="center" pad="large">
        <Box fill><Footer /></Box>
      </Box>
    </Grommet>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
