export const click = (name) => typeof window !== "undefined" && window.gtag && window.gtag("event", "click", {name})
export const outboundClick = (href, name) => typeof window !== "undefined" && window.gtag && window.gtag("event", "click", {
  event_category: "outbound",
  event_label: href,
  name,
  transport_type: "beacon"
})
export const buy = (data) => typeof window !== "undefined" && window.gtag && window.gtag("event", "purchase", data)
export const event = (event) => typeof window !== "undefined" && window.gtag && window.gtag("event", event)
export const checkoutProgress = (data) => typeof window !== "undefined" && window.gtag && window.gtag("event", "checkout_progress", data)
export const generateLead = (type, data = {}) => typeof window !== "undefined" && window.gtag && window.gtag("event", "generate_lead", { type, ...data })