import React from "react";
import { Box, ResponsiveContext, Text } from "grommet";

import { Logo } from "./logo";

import styled from "styled-components";
import Link from "./link"
import { outboundClick } from "../helpers/analytics"

const StyledAnchor = styled(Link)`
  font-weight: 200;
`;

const data = [
  {title: 'mitabits', menu: [{name: 'начало', path: '/'}, {name: 'demo', path: `${process.env.GATSBY_DASHBOARD_URL}/demo`, outbound: true}, {name: 'dashboard', path: process.env.GATSBY_DASHBOARD_URL, outbound: true}, {name: 'контакти', path: '/contact-us'}]},
  {title: 'условия', menu: [{name: 'ползване', path: '/terms-and-conditions'}, {name: 'поверителност', path: '/privacy-policy'}]},
];

const getContent = () => {
  return data.map((item, index) => (
    <Box gap="medium" key={index + item.title}>
      <Text weight="bold" size="small">
        {item.title}
      </Text>
      {item.menu.map(menuItem => (
        <StyledAnchor
          key={menuItem.name}
          to={menuItem.path}
          size="small"
          color="black"
          onClick={() => {menuItem.outbound && outboundClick(menuItem.path, menuItem.name)}}
          target={menuItem.outbound ? '_blank' : '_self'}
        >
          {menuItem.name}
        </StyledAnchor>
      ))}
    </Box>
  ));
};

const FooterContent = () => (
  <ResponsiveContext.Consumer>
    {size => (
      <Box
        direction="row"
        gap={size !== "xsmall" && size !== "small" ? "xlarge" : "small"}
      >
        {getContent()}
      </Box>
    )}
  </ResponsiveContext.Consumer>
);

export { FooterContent };

const Footer = ({ ...rest }) => (
  <ResponsiveContext.Consumer>
    {size => (
      <Box
        direction="row"
        justify="between"
        border={{ side: "top", color: "light-4" }}
        pad={{ top: "xlarge" }}
        {...rest}
      >
        <Box gap="large" align="start" justify='between'>
          <Box
            gap="small"
            direction="row-responsive"
            align="center"
          >
            <Logo />
            {size !== "small" && size !== "xsmall" && (
              <Text weight="bold" size="large" color='black'>
                mitabits
              </Text>
            )}
          </Box>
          <Box>
              <Text textAlign="center" size="small">
                © {new Date().getFullYear()}, all rights reserved.
              </Text>
          </Box>
          {/*<SocialMedia />*/}
        </Box>
        <FooterContent />
      </Box>
    )}
  </ResponsiveContext.Consumer>
);

export { Footer };